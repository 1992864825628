// Generated by Framer (e1877f1)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Video } from "https://framerusercontent.com/modules/lRDHiNWNVWmE0lqtoVHP/IZ0vSV62Dv7ax4rBiGUk/Video.js";
const VideoFonts = getFonts(Video);

const cycleOrder = ["WWhBxfoj4"];

const serializationHash = "framer-Yfsi6"

const variantClassNames = {WWhBxfoj4: "framer-v-ickkrc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "WWhBxfoj4", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ickkrc", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"WWhBxfoj4"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><ComponentViewportProvider ><motion.div className={"framer-vew1h8-container"} layoutDependency={layoutDependency} layoutId={"Tn3Rm3c3q-container"}><Video backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} controls={false} height={"100%"} id={"Tn3Rm3c3q"} isMixedBorderRadius={false} layoutId={"Tn3Rm3c3q"} loop muted objectFit={"cover"} playing={false} posterEnabled={false} srcType={"URL"} srcUrl={"https://assets.mixkit.co/videos/preview/mixkit-shining-sun-in-the-sky-surrounded-by-moving-clouds-31793-small.mp4"} startTime={0} style={{height: "100%", width: "100%"}} topLeftRadius={0} topRightRadius={0} volume={25} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Yfsi6.framer-13lkqlf, .framer-Yfsi6 .framer-13lkqlf { display: block; }", ".framer-Yfsi6.framer-ickkrc { height: 709px; overflow: hidden; position: relative; width: 856px; }", ".framer-Yfsi6 .framer-vew1h8-container { flex: none; height: 231px; left: 214px; position: absolute; top: 124px; width: 320px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 709
 * @framerIntrinsicWidth 856
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerrnpNLony6: React.ComponentType<Props> = withCSS(Component, css, "framer-Yfsi6") as typeof Component;
export default FramerrnpNLony6;

FramerrnpNLony6.displayName = "VidoApagar";

FramerrnpNLony6.defaultProps = {height: 709, width: 856};

addFonts(FramerrnpNLony6, [{explicitInter: true, fonts: []}, ...VideoFonts], {supportsExplicitInterCodegen: true})